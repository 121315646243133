<template>
  <section class="block inline-grid-container container-padding project-page__slider">
    <div class="block-title">
      <h3>{{$t('newsAndAd1')}}</h3>
      <CustomLink
        :title="$t('allNews')"
        link="/news"
      />
    </div>
    <div class="block-content dynamic-content">
      <swiper
        ref="mySwiper"
        :options="swiperOptions"
        class="swiper"
        :delete-instance-on-destroy="true"
        :cleanup-styles-on-destroy="true"
      >
        <swiper-slide
          v-for="el in mainNews"
          :key="el.id"
          class="slider__slide"
        >
          <NewsItem
            :news="el"
            max-width
            @openNews="openNews"
          />
        </swiper-slide>
        <div
          class="swiper-scrollbar"
          slot="pagination"
        ></div>
      </swiper>
      <div class="separator"></div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import NewsItem from '../../../components/NewsItem.vue';
import CustomLink from '../../../components/UI/CustomLink.vue';

export default {
  name: 'News',

  computed: {
    ...mapState('news', ['mainNews']),
    ...mapState(['swiperOptions']),
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
  },

  async mounted() {
    if (this.mainNews.length === 0) {
      await this.getNews({page: 1, pageSize: 6 })
    }
  },

  methods: {
    ...mapActions('news', ['getNews']),
    openNews(id) {
      this.$router.push(`/one-news/${id}`)
    },
  },

  components: { NewsItem, Swiper, SwiperSlide, CustomLink },

};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.news {
  margin-bottom: 120px;
}

/*.news-title-block {
  display: flex;
  padding: 0 0 35px 210px;
  p {
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
  }
}*/

.news-wrapper {
  // padding: 0 0 0 90px;
  margin: 0 auto;
}

.slider__slide {
  width: 360px;
}

@media screen and (max-width: 1100px) {
  .news {
    margin-bottom: 20px;
  }

  /*  .news-title-block {
    padding: 0 0 25px 25px;
    display: flex;
  }*/

  /*  .news-wrapper {
    padding: 0 25px;
    margin-left: -5px;
  }*/

  .slider__slide {
    width: 310px;
  }
}

@media (max-width: 350px) {
  .slider__slide {
    width: fit-content;
  }
}

</style>
