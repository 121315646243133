<template>
    <section class="first">
        <div class="main-title inline-grid-container container-padding">
          <h1>{{baseInfo && baseInfo.title}}</h1>
            <FundVideo
              :url="baseInfo.video_url"
              :fundPurpose="fundPurpose"
              v-if="!!baseInfo.show_video && !!baseInfo.video_url"
            />
        </div>

      <div class="upgrades inline-grid-container container-padding">
        <p class="upgrades-title">{{$t('ourUpgrades')}}</p>

        <swiper
          ref="mediaSwiper" :options="swiperOptions" class="swiper upgrades-list_swiper"
          :delete-instance-on-destroy="true"
          :cleanup-styles-on-destroy="true"
        >
          <swiper-slide v-for="el in upgrades" :key="el.id" class="slider__slide upgrades-list__item" >
            <div class="item" @click="relink(el)">
              <v-img :src="`${ApiUrl}v1/images${el.partner_url}`"
                     alt="main" v-if="el.partner_url"
              />
              <p>{{el.title}}</p>
            </div>
          </swiper-slide>
          <div class="swiper-scrollbar" slot="pagination"></div>
        </swiper>

        <div class="upgrades-list">
          <div class="upgrades-list__item" v-for="el in upgrades" :key="el.id"
          @click="$router.push(`/one-news/${el.id}`)"
          >
            <v-img :src="`${ApiUrl}v1/images${el.partner_url}`"
                   alt="main" v-if="el.partner_url"
            />
            <p>{{el.title}}</p>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
import fundPurpose from '@/assets/img/fund_purpose.svg'
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import {ApiUrl} from "@/global";
import FundVideo from "@/views/mainPage/components/AboutFond/FundVideo.vue";

export default {
  name: 'Main',
  data() {
    return {
      fundPurpose,
      ApiUrl
    }
  },

  computed: {
    ...mapState(['baseInfo']),
    ...mapState('news', ['upgrades']),
    ...mapState(['swiperOptions']),
    swiper() {
      return this.$refs.mediaSwiper.$swiper
    }
  },
  methods: {
    relink(el) {
      this.$router.push(`/one-news/${el.id}`)
    }
  },
  comments: { Swiper, SwiperSlide },
  components: { FundVideo },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.first {
  margin-bottom: 56px;
}

.main-title {
  width: 100%;
  h1 {
    grid-column: 5/12;
    font-size: 36px;
    line-height: 58px;
    max-width: 860px;
    font-weight: 500;
    white-space: pre-line;
  }
}
  .upgrades {
    width: 100%;
    margin-top: 60px;

    .upgrades-title {
      font-weight: 600;
      font-size: 26px;
      line-height: 30px;
      margin-bottom: 20px;
      grid-area: 1 / 2;
    }
  }
    .upgrades-list {
      grid-area: 2 / 2 / 2 / 13;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(3 , minmax(285px, 1fr));
    }

.upgrades-list_swiper{
  display: none;
}

    .upgrades-list__item {
      width: 100%;
      height: 118px;
      background: #F2F2F2;
      border-radius: 20px;
      display: flex;
      align-items: center;
      padding: 0 40px;
      cursor: pointer;
      .v-image {
        max-width: 34px;
        margin-right: 10px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
      }
    }

.upgrades-list::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1100px) {
  .first {
    width: 100%;
  }

  .item{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .main-title {
    h1 {
      grid-column: 1/13;
      font-size: 30px;
      line-height: 50px;
      max-width: 100%;
    }
  }

    .upgrades {
      padding-right: 0 !important;
      .upgrades-title {
        grid-area: 1/1;
        font-size: 20px;
      }

    }
      .upgrades-list{
        display: none;
      }
      .upgrades-list_swiper {
        grid-area: 2 / 1 / 2 / 13;
        display: block;
        .upgrades-list__item {
          height: 92px;
          width: 270px;
          padding: 0 30px;
          margin-right: 10px;
          p{
            font-size: 14px
          }
        }
      }

}

@media (max-width: 600px){
  .main-title {
    h1 {
      font-size: 20px !important;
      line-height: 33px;
    }
  }
}

</style>
