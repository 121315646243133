<template>
  <section class="fade" v-if="!loading">
    <Main />
    <Competence />
    <section class="tomap">
      <div class="bg"><img :src="require('@/assets/img/map.png')" alt=""></div>
      <div class="leaflet1"><img :src="require('@/assets/img/leaflet1.svg')" alt=""></div>
      <div class="leaflet2"><img :src="require('@/assets/img/leaflet2.svg')" alt=""></div>
      <div class="title">Интерактивная карта ферм</div>
      <div class="desc">Удобный раздел, с помощью которого вы можете изучить в каком регионе был снят выпуск про ферму, чем она интересна и как до неё добраться</div>
      <a @click.prevent="$router.push('/map')" href="/map">
        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 7C6.25 4.92893 7.92893 3.25 10 3.25C12.0711 3.25 13.75 4.92893 13.75 7C13.75 9.07107 12.0711 10.75 10 10.75C7.92893 10.75 6.25 9.07107 6.25 7ZM10 4.75C8.75736 4.75 7.75 5.75736 7.75 7C7.75 8.24264 8.75736 9.25 10 9.25C11.2426 9.25 12.25 8.24264 12.25 7C12.25 5.75736 11.2426 4.75 10 4.75Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.32343 5.64635C1.90431 6.25029 1.75 6.7227 1.75 7C1.75 7.2773 1.90431 7.74971 2.32343 8.35365C2.72857 8.93744 3.33078 9.57028 4.09267 10.155C5.61978 11.3271 7.71345 12.25 10 12.25C12.2865 12.25 14.3802 11.3271 15.9073 10.155C16.6692 9.57028 17.2714 8.93744 17.6766 8.35365C18.0957 7.74971 18.25 7.2773 18.25 7C18.25 6.7227 18.0957 6.25029 17.6766 5.64635C17.2714 5.06256 16.6692 4.42972 15.9073 3.84497C14.3802 2.67292 12.2865 1.75 10 1.75C7.71345 1.75 5.61978 2.67292 4.09267 3.84497C3.33078 4.42972 2.72857 5.06256 2.32343 5.64635ZM3.17941 2.65503C4.90965 1.32708 7.31598 0.25 10 0.25C12.684 0.25 15.0903 1.32708 16.8206 2.65503C17.6874 3.32028 18.4032 4.06244 18.9089 4.79115C19.4006 5.49971 19.75 6.2773 19.75 7C19.75 7.7227 19.4006 8.50029 18.9089 9.20885C18.4032 9.93756 17.6874 10.6797 16.8206 11.345C15.0903 12.6729 12.684 13.75 10 13.75C7.31598 13.75 4.90965 12.6729 3.17941 11.345C2.31262 10.6797 1.59681 9.93756 1.0911 9.20885C0.599371 8.50029 0.25 7.7227 0.25 7C0.25 6.2773 0.599371 5.49971 1.0911 4.79115C1.59681 4.06244 2.31262 3.32028 3.17941 2.65503Z"/>
        </svg>
        Изучить
      </a>
    </section>
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition">-->
      <Projects />
<!--    </v-lazy>-->
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition" >-->
      <MediaProjects />
<!--    </v-lazy>-->
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition">-->
      <AboutFond />
<!--    </v-lazy>-->
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition">-->
      <Team />
      <div style="margin-bottom: 30px"></div>
<!--    </v-lazy>-->
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition">-->
      <News />
<!--    </v-lazy>-->
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition">-->
      <ContactsBlock @changeFeedback="changeFeedback"
                :phone="baseInfo.phone" :address="baseInfo.address" :email="baseInfo.email"
      />
<!--    </v-lazy>-->
<!--    <Feedback :isFeedback="isFeedback" @changeFeedback="changeFeedback"/>-->
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Main from './components/Main.vue';
import Feedback from '../../components/Feedback.vue';
import Competence from './components/Competence.vue';
import Projects from './components/Projects.vue';
import MediaProjects from './components/MediaProjects.vue';
import AboutFond from './components/AboutFond/AboutFond.vue';
import Team from './components/Team.vue';
import News from './components/News.vue';
import ContactsBlock from './components/ContactsBlock.vue';

export default {
  name: 'MainPage',

  data() {
    return {
      loading: true,
      isFeedback: false,
      lazyOptions: {
        threshold: 0.1,
      },
    }
  },

  computed: {
    ...mapState(['baseInfo', 'scrollY']),
  },

  methods: {
    ...mapActions('projects', ['getProjects']),
    ...mapActions('news', ['getNews', 'getUpgrades']),
    ...mapActions('mediaProjects', ['getVideos']),
    ...mapActions(['getBaseInfo']),
    changeFeedback(val) {
      this.isFeedback = val
    },
  },

  async mounted() {
    if (!this.baseInfo) {
      await this.getBaseInfo()
    }
    await this.getUpgrades()
    this.loading = false
    await this.getProjects({page: 1, pageSize: 5})
    await this.getNews({page: 1, pageSize: 6})
    await this.getVideos({page: 1, pageSize: 6})
  },

  updated() {
    window.scrollTo({ top: this.scrollY, behavior: 'smooth' })
  },

  components: {
    Main,
    Feedback,
    Competence,
    Projects,
    MediaProjects,
    Team,
    AboutFond,
    News,
    ContactsBlock,
  },
};
</script>

<style scoped lang="scss">
section.tomap{
  position: relative;
  margin-left: 5.625%;
  margin-right: 5.625%;
  margin-bottom: 78px;
  padding: 46px 0 70px;
  background: #EEF9F1;
  border-radius: 20px;
  overflow: hidden;
  @media(max-width:1100px) {
    padding: 40px 0 30px;
  }
  .bg{
    position: absolute;
    left: 44px;
    right: 44px;
    bottom: 0;
    @media(max-width:1100px) {
      left: 10px;
      right: 10px;
    }
    img{
      display: block;
      width: 100%;
    }
  }
  .leaflet1{
    position: absolute;
    top: 40px;
    left: 0;
    width: 150px;
    @media(max-width:1100px) {
      width: 100px;
    }
    @media(max-width:480px) {
      top: 10px;
      width: 40px;
    }
    img{
      display: block;
      width: 100%;
    }
  }
  .leaflet2{
    position: absolute;
    top: 0;
    right: 0;
    width: 180px;
    @media(max-width:1100px) {
      width: 130px;
    }
    @media(max-width:480px) {
      width: 60px;
    }
    img{
      display: block;
      width: 100%;
    }
  }
  .title{
    position: relative;
    padding: 0 0 42px;
    font-weight: 600;
    font-size: 36px!important;
    line-height: 44px;
    text-align: center;
    color: $text;
    @media(max-width:1100px) {
      padding: 0 0 20px;
      font-size: 32px !important;
      line-height: 40px;
    }
    @media(max-width:768px) {
      font-size: 24px !important;
      line-height: 28px;
    }
    @media(max-width:576px) {
      font-size: 20px !important;
      line-height: 20px;
    }
    @media(max-width:480px) {
      font-size: 18px !important;
      line-height: 18px;
    }
  }
  .desc{
    position: relative;
    max-width: 434px;
    margin: 0 auto;
    padding: 0 0 55px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #8E8E8E;
    @media(max-width:1100px) {
      padding: 0;
    }
    @media(max-width:768px) {
      max-width: 390px;
      font-size: 14px;
      line-height: 22px;
    }
    @media(max-width:480px) {
      padding: 0 20px;
    }
  }
  a{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 340px;
    line-height: 70px;
    background: #13a538;
    border-radius: 10px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    margin: 70px auto 0;
    @media(max-width:1100px) {
      margin: 40px auto 0;
    }
    @media(max-width:480px) {
      max-width: 300px;
    }
    svg{
      display: block;
      margin: 0 12px 0 0;
      fill: #fff;
    }
    &:hover{
      background: #ffd400;
      color: #13a538!important;
      svg{
        fill: #13a538;
      }
    }
  }
}
</style>
