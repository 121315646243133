<template>
  <div
    class="item"
    :class="{ maxWidth: maxWidth, maxWidthFull:maxWidthFull }"
    @click="$emit('openNews', news.id)"
  >
    <v-img
      :src="`${ApiUrl}v1/images${news.pic_url}`"
      alt="news"
      height="430"
      class="overlay-image"
    />
    <div class="item__title-block">
      <p class="item__title">{{news.title}}</p>
      <img
        :src="`${ApiUrl}v1/images${news.partner_url}`"
        alt="main"
        v-if="news.partner_url"
        class="item__image"
      >
    </div>
    <p class="item__date">
      {{new Date(news.data).toLocaleString(currentLang, {day: 'numeric', month: 'numeric', year: 'numeric'})}}
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ApiUrl } from "@/global";

export default {
  name: 'NewsItem',
  data () {
    return {
      ApiUrl
    }
  },

  computed: {
    ...mapState(['lang']),
    currentLang () {
      return this.lang === 'ru' ? 'ru' : 'en'
    },
  },

  props: {
    news: {
      type: Object,
    },
    maxWidth: {
      type: Boolean,
      default: false,
    },
    maxWidthFull: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.maxWidth {
  width: 340px;
}

.item {
  min-height: 550px;
  box-sizing: border-box;
  border-radius: $border-radius;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 32px;
  .v-image {
    min-height: 430px;
    border-radius: $border-radius;
  }

  &__title-block {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    max-width: 340px;
  }

  &__title {
    font-size: 18px;
    line-height: 25px;
    height: 72px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 0 20px 0;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-line-clamp: 3;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    box-orient: vertical;
  }
  &__image {
    margin-left: 15px;
  }

  &__date {
    max-width: 340px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    transition: 0.3s;
    color: #a0a0a0;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    p {
      margin-right: 10px;
      cursor: pointer;
      &:hover {
        color: white;
      }
    }
  }

  &:hover {
    .item__title,
    .item__date {
      transition: 0.3s;
    }
  }
}

.item__image{
  height: 34px;
}

@media screen and (max-width: 1100px) {
  .item {
    width: 300px;
    height: 560px;
    &.maxWidth {
      max-width: 300px;
    }

    &.maxWidthFull {
      width: 100%;
      max-width: 600px;
      height: auto;
    }

    &__title {
      font-size: 16px;
      line-height: 22px;
      height: 65px;
    }
    .v-image {
      min-height: 375px;
    }
  }
}

@media screen and (max-width: 350px) {
  .item {
    &.maxWidth {
      max-width: 270px;
      margin-right: 10px;
    }
  }
}

@media (max-width: 900px) {
  .item__title-block {
    max-width: 440px;
  }
}
</style>
