<template>
  <section :class="[ width>screenWidth? 'container-margin' : 'container-padding', 'inline-grid-container', 'green-wrapper']" class="competence-container">
      <div class="block-title-abs">
        <svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M33 0.0804623C9.51643 -0.515514 6.44789
        2.06705 3.9018 12.2781L0 27.9324C31.3996 28.7271 26.5653 22.6945 33 0.0804623Z"
                fill="#FFD400"/>
        </svg>
        <h3>{{$t('competence')}}</h3>
      </div>

      <ul class="competence-list">
        <li v-for="(el, idx) in baseInfo.competence" :key="idx" :class="[{ 'center-item': idx===1 || idx===4 }, { 'last-item': idx===2 || idx===5 }, 'competence-item']">
            <p class="number">0{{idx + 1}}</p>
            <p class="text">{{el}}</p>
        </li>
      </ul>
    <swiper ref="mediaSwiper" :options="swiperOptions" class="swiper competence-swiper"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true">
        <swiper-slide v-for="(el, index) in baseInfo.competence" :key="el.id" class="slider__slide">
          <div class="swiper-item">
            <p class="number">0{{index+1}}</p>
            <p class="text">{{el}}</p>
          </div>
        </swiper-slide>
    </swiper>
  </section>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'Competence',
  data() {
    return {
      width: 0,
      screenWidth: 1096,

    }
  },
  computed: {
    ...mapState(['swiperOptions', 'baseInfo']),
    swiper() {
      return this.$refs.mediaSwiper.$swiper
    }
  },
  methods: {
    calcWidth(){
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi) )/i.test(ua) || /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
       this.screenWidth = 1100
      } else {this.screenWidth = 1096}
      this.width = document.getElementsByTagName("html")[0].clientWidth
    }
  },
  mounted() {
    this.calcWidth()
    window.addEventListener("resize", this.calcWidth)
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.green-wrapper {
  border-radius: 20px !important;
}

.block-title-abs {
  h3 {
    color: white;
    max-width: 200px;
  }
}

.swiper-item {
  p {
    color: white;
  }
  padding-right: 10px;
}

.competence-list {
  grid-column: 5 / 13;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(8, minmax(0px, 1fr));
  grid-gap: 20px;
}

.center-item{
  grid-column: 4/6 !important;
}

.last-item{
  grid-column: 7/9 !important;
}

.competence-item {
  max-width: 200px;
  min-width: 155px;
  margin-bottom: 50px;
  grid-column: span 2;
    p {
      color: white;
      font-weight: 500;
      overflow-wrap: break-word;
    }
}
.number {
  font-weight: 600 !important;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 16px;
}
@media (max-width: 375px){
  .competence-list {
    grid-gap: 2.9%;
  }
}

.competence-swiper::-webkit-scrollbar {
  display: none;
}

@media (min-width: 1101px) {
  .competence-swiper {
    display: none;
  }
}

@media (min-width: 1101px) and (max-width: 1450px) {
  .competence-list {
    grid-template-columns: auto;
  }
}

@media (max-width: 1100px){
  .competence-list {
    display: none;
  }

  .green-wrapper{
    display: block !important;
    padding-right: 0 !important;
  }

  .competence-container {
    margin-bottom: 56px !important;
  }
}

.competence-container {
  margin-bottom: 75px;
}
</style>
